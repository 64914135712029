import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import GridList from '../media/GridList';
import { getImage } from '../../server/server';
import download from 'downloadjs';

function PreviewImagesModal({ modalIsOpen, afterOpenModal, closeModal, ids, digitalFields }: any) {
    useEffect(() => {
        ReactModal.setAppElement('#root');
    }, []);

    const handleDownload = (url: string, id: string) => {
        fetch(url)
            .then(response => {
                const contentType = response.headers.get('Content-Type') || 'image/jpeg';

                return response.blob().then(blob => ({
                    blob,
                    contentType
                }));
            })
            .then(({ blob, contentType }) => {
                download(blob, id, contentType);
            })
            .catch(error => console.error('Error downloading the image:', error));
    };

    const textFields = digitalFields?.filter((f: any) => f.type === "text") || [];
    const imageFields = digitalFields?.filter((f: any) => f.type === "image") || [];
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={{
                overlay: {
                    zIndex: 10,
                },
                content: {
                    width: 600,
                    height: 'fit-content',
                    padding: 0,
                    opacity: 1,
                    minWidth: 600,
                    margin: 'auto',
                    marginTop: '5%',
                    backgroundColor: 'white',
                    maxHeight: '80%',
                    border: 'none',
                    boxShadow: 'none',
                    overflow: 'hidden',
                },
            }}
        >
            <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center w-full p-10">
                <h2 className="font-bold text-xl">Used media</h2>

                {textFields?.map((f: any, index: any) => (
                    <div className='w-full mt-2' key={index}>
                        <p className="font-bold">{f.name}</p>1
                        <p className="field-value">{f.value}</p>
                    </div>
                ))}

                <div className="grid grid-cols-3 gap-1 cursor-pointer">
                    {[...ids?.map((id: any) => ({ value: id }))]?.map((item, index) => {
                        const imageUrl = getImage(item.value);
                        return (
                            <div className="flex flex-col border border-[#aeaeae] cursor-pointer relative" key={`image-${item?.value}-${index}`} onClick={() => handleDownload(imageUrl, item.value)}>
                                <img src={imageUrl} alt={item?.name || 'Image'} className="aspect-square object-cover object-top" loading="lazy" />
                                {item.name && <p className="font-bold">{item.name}</p>}
                            </div>
                        );
                    })}
                </div>

            </div>
        </Modal>
    );
}

export default PreviewImagesModal;
