import React, { useState } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { FaFilePdf, FaImage, FaTrash } from 'react-icons/fa';
import { fetchPDF, getImage } from '../../../server/server';
import { Order, OrderItem } from '../OrderInterfaces';
import download from 'downloadjs';
import PreviewImagesModal from '../../modals/PreviewImagesModal';
import { set } from 'date-fns';

interface OrderItemsTableProps {
	orderItems: OrderItem[];
	onDeleteRowCategory: (id: GridRowId) => void;
	order: Order;
	paginationModel: any;
	rowCountState: any;
	isLoading: boolean;
	setPaginationModel: React.Dispatch<React.SetStateAction<any>>;
}

const OrderItemsTable: React.FC<OrderItemsTableProps> = ({ orderItems, onDeleteRowCategory, order, paginationModel, rowCountState, isLoading, setPaginationModel }) => {

	const [imagesModalOpen, setImagesModalOpen] = useState(false)
	const [selectedImageIds, setSelectedImageIds] = useState([])
	const [digitalFields, setDigitalFields] = useState([])


	const closeImagesModal = () => {
		setImagesModalOpen(false);
	};
	const onDownloadPdf = async (id: any) => {
		try {
			const pdfBlob = await fetchPDF(id);
			const dateStr = new Date()
				.toLocaleDateString('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				})
				.split('/')
				.join('-');
			const fileName = `${dateStr}_${id}.pdf`;
			download(pdfBlob, fileName, 'application/pdf');
		} catch (error) {
			console.error('Failed to download PDF:', error);
		}
	}

	const handleSetImage = (row: any) => {
		setImagesModalOpen(true)
		console.log(row)
		if (row?.product?.digitalProductFields?.length > 0) {
			setDigitalFields(row?.product?.digitalProductFields)
			setSelectedImageIds(row.usedImageIds)
		} else {
			setSelectedImageIds(row.usedImageIds)
		}
	}
	const columns: GridColDef[] = [
		{
			field: 'file',
			headerName: 'Image',
			width: 200,
			renderCell: (params) => {
				const isImage = params?.row?.product?.imageOrders[0]?.file.mimetype.includes('image');
				const fileId = params?.row?.product?.imageOrders[0]?.file?.id;

				if (fileId) {
					console.log('File ID:', fileId);
					return isImage ? (
						<img style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={getImage(fileId)} alt="product" width="50" />
					) : (
						<video style={{ pointerEvents: 'none', width: 150, height: 50, objectFit: 'cover', objectPosition: 'center' }} muted>
							<source src={getImage(fileId)} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					);
				}

				return <p>No images found</p>;
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 200,
			flex: 1,
			valueGetter: (params) => params?.row?.product?.name,
		},
		{ field: 'sku', headerName: 'SKU', minWidth: 150, valueGetter: (params) => params?.row?.product?.sku },
		{ field: 'price', headerName: 'Regular cost', minWidth: 150, valueGetter: (params) => `${params?.row?.product?.commercialOptions?.price} ${order?.currency?.symbol}` },
		{
			field: 'action',
			type: 'actions',
			headerName: 'Download',
			width: 60,
			getActions: (params) => [
				params.row?.product?.digitalProductFields?.length > 0
					? <></>
					: <GridActionsCellItem
						icon={<FaFilePdf style={{ width: 20, height: 20, color: '#007FFF' }} />}
						label="Download PDF"
						onClick={() => onDownloadPdf(params.id)}
					/>
			],
		},
		{ field: 'cost', headerName: 'Cost', minWidth: 200, valueGetter: (params) => `${params?.row?.cost} ${order?.currency?.symbol}` },
		{ field: 'itemQty', headerName: 'Qty', minWidth: 100, valueGetter: (params) => `x${params?.row?.quantity}` },
		{ field: 'tax', headerName: 'Tax', minWidth: 100, valueGetter: (params) => `${params?.row?.tax} ${order?.currency?.symbol}` },
		{ field: 'total', headerName: 'Total', minWidth: 100, valueGetter: (params) => `${params?.row?.itemTotal} ${order?.currency?.symbol}` },

		{
			field: 'actions',
			type: 'actions',
			width: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<FaImage style={{ width: 20, height: 20, color: '#b70000' }} />}
					label="See images"
					onClick={() => handleSetImage(params.row)}
				/>,
				// <GridActionsCellItem
				// 	icon={<FaFilePdf style={{ width: 20, height: 20, color: '#007FFF' }} />}
				// 	label="Download PDF"
				// 	onClick={() => onDownloadPdf(params.id)}
				// />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => onDeleteRowCategory(params.id)} />,
			],
		},
	];

	return (
		<div>
			<DataGrid
				className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4 m-4"
				rows={orderItems}
				columns={columns}
				rowCount={rowCountState}
				loading={isLoading}
				pageSizeOptions={[15, 30, 50]}
				paginationModel={paginationModel}
				paginationMode="client"
				onPaginationModelChange={(newModel) => {
					setPaginationModel(newModel);
				}}
			/>
			<PreviewImagesModal modalIsOpen={imagesModalOpen} closeModal={closeImagesModal} ids={selectedImageIds} digitalFields={digitalFields} />
		</div>
	);
};

export default OrderItemsTable;
