import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddSEOImageModal from './AddSEOImageModal';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/DeleteIcon.svg';
import { useForm } from 'react-hook-form';
import { createSeo, getImage, uploadFile } from '../../../server/server';
import { CreateSeoDto } from '../../../model/seo.dto';

function NewProductSEOForm({ product, handleFieldValuesChanges, error }: { product: any; handleFieldValuesChanges: any; error: any }) {
	const [addSEOImageModalOpen, setAddSEOImageModalOpen] = useState(false);
	const [SEOImage, setSEOImage] = useState('');
	const [SEOSelectedFile, setSEOSelectedFile] = useState(undefined);

	return (
		<div className="flex  w-full flex-col mt-10 border border-mediumGrey rounded-md p-[30px] gap-5">
			<div className="flex flex-row items-center justify-between">
				<p className="text-darkGrey text-lg  px-2">SEO</p>
			</div>
			<div className="grid grid-cols-3 gap-5">
				<div className="">
					<TextField required className=" background-transparent " value={product.metaTitle ?? ''} onChange={(e) => handleFieldValuesChanges(e, 'metaTitle')} helperText={error?.metaTitle ?? ''} error={!!error?.metaTitle} fullWidth label="Meta Title" variant="filled" />
				</div>
				<div className="">
					<TextField
						required
						className=" background-transparent "
						value={product.metaDescription ?? ''}
						onChange={(e) => handleFieldValuesChanges(e, 'metaDescription')}
						helperText={error?.metaDescription ?? ''}
						error={!!error?.metaDescription}
						fullWidth
						label="Meta Description"
						variant="filled"
					/>
				</div>
				<div className="">
					<TextField required className=" background-transparent " value={product.urlSlug ?? ''} onChange={(e) => handleFieldValuesChanges(e, 'urlSlug')} helperText={error?.urlSlug ?? ''} error={!!error?.urlSlug} fullWidth label="Meta Url Slug" variant="filled" />
				</div>
			</div>
			<div className="grid grid-cols-3 gap-5">
				<div className="col-span-2">
					<TextField required className=" background-transparent " value={product.metaKeywords ?? ''} onChange={(e) => handleFieldValuesChanges(e, 'metaKeywords')} helperText={error?.metaKeywords ?? ''} error={!!error?.metaKeywords} fullWidth label="Meta Keywords" variant="filled" />
				</div>
			</div>
		</div>
	);
}

export default NewProductSEOForm;
